import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.foody.gomaplus.tech/api",
  }),

  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => `products/branch/${localStorage.getItem("branch_id")}`,
    }),
    getProductDetails: builder.query({
      query: (id) => `product/${id}`,
    }),
    getProductIngredients: builder.query({
      query: (id) => `extraIng/product/${id}`,
    }),
    getProductTax: builder.query({
      query: (id) => `taxRate/branch/${localStorage.getItem("branch_id")}`,
    }),
    getProductRemoveIngredients: builder.query({
      query: (id) => `remove/ingredient/product/${id}`,
    }),

    // getProductPopular: builder.mutation({
    //   query: () => ({
    //     url: `mostRequestedProduct/1`,
    //     method: "POST",
    //     body: {},
    //   }),
    // }),

    // getProductMostRated: builder.mutation({
    //   query: () => `mostRatedProduct/1`,
    //   init: {
    //     method: "POST",
    //   },
    // }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductDetailsQuery,
  useGetProductIngredientsQuery,
  useGetProductTaxQuery,
  useGetProductRemoveIngredientsQuery,
  useGetProductPopularMutation,
} = productsApi;
