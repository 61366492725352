import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const offersApi = createApi({
  reducerPath: "offersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.foody.gomaplus.tech/api/",
  }),
  endpoints: (builder) => ({
    getAllOffers: builder.query({
      query: () => `offer/branch/${localStorage.getItem("branch_id")}`,
    }),
  }),
});

export const { useGetAllOffersQuery } = offersApi;
