import { Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Spinner from "../components/skeletons/Spinner";
import Language from "../pages/Language";

const HomePage = lazy(() => import("../pages/HomePage"));
const Cart = lazy(() => import("../pages/Cart"));
const ProductDetails = lazy(() => import("../pages/ProductDetails"));
const SuccessfullOrder = lazy(() => import("../pages/SuccessfullOrder"));
const Popular = lazy(() => import("../pages/Popular"));
const TopRated = lazy(() => import("../pages/TopRated"));
const Search = lazy(() => import("../pages/Search"));

function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Language />} />
      <Route
        path="/home"
        element={
          <Suspense>
            <HomePage />
          </Suspense>
        }
      />

      <Route
        path="cart/successfully"
        element={
          <Suspense fallback={<Spinner />}>
            <SuccessfullOrder />
          </Suspense>
        }
      />
      <Route
        path="cart"
        element={
          <Suspense fallback={<Spinner />}>
            <Cart />
          </Suspense>
        }
      />
      <Route
        path="/popular"
        element={
          <Suspense fallback={<Spinner />}>
            <Popular />
          </Suspense>
        }
      />
      <Route
        path="/topRated"
        element={
          <Suspense fallback={<Spinner />}>
            <TopRated />
          </Suspense>
        }
      />
      <Route
        path="/search/:text"
        element={
          <Suspense fallback={<Spinner />}>
            <Search />
          </Suspense>
        }
      />
      <Route
        path="/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ProductDetails />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default Routers;
