import { useEffect, useState } from "react";
import "./App.css";
import { useThemeHook } from "./components/ThemeProvider";
import PreLoader from "./components/preloader/PreLoader";
import Routers from "./routers/Routers";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { AddToCart } from "./redux/slices/cartSlice";
import { useDispatch } from "react-redux";
import { AES, enc } from "crypto-js";

function App() {
  const dispatch = useDispatch();
  const [theme] = useThemeHook();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table = searchParams.get("table_id");
  const branch = searchParams.get("branch_id");

  console.log(table, branch);

  const fetchStatus = async () => {
    axios
      .post("https://api.foody.gomaplus.tech/api/order/getOrderForEdit", {
        table_id: localStorage.getItem("table_id"),
        branch_id: localStorage.getItem("branch_id"),
      })
      .then((response) => {
        localStorage.setItem("status", response.data.data.status);
        localStorage.setItem("order_id", response.data.data.id);

        if (response.data.data.status === 1) {
          const cartItems = [];
          let i = 0;
          response.data.data.products.forEach((item) => {
            let newPrice = 0;
            for (let index = 0; index < item.extra.length; index++) {
              newPrice += item.extra[index].price_per_piece;
            }

            const cartItem = {
              nourId: i,
              id: item.id,
              name: item.name,
              name_ar: item.name_ar,
              image: item.image,
              price: newPrice + item.price,
              extraIngredients: item.extra,
              note: item.note == null ? "" : item.note,
              quantity: item.qty,
              removedIngredients: item.removeIngredient,
            };

            i++;
            cartItems.push(cartItem);
          });
          cartItems.forEach((cartItem) => {
            dispatch(AddToCart(cartItem));
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  if (table && branch) {
    const tableDecByts = AES.decrypt(
      table,
      process.env.REACT_APP_NOT_SECRET_KEY
    );
    const tableDec = tableDecByts.toString(enc.Utf8);

    const branchDecByts = AES.decrypt(
      branch,
      process.env.REACT_APP_NOT_SECRET_KEY
    );
    const branchDec = branchDecByts.toString(enc.Utf8);

    console.log(branchDec, tableDec);

    if (branchDec === "" || tableDec === "") {
      return (
        <p className="text-center text-red-500 p-6">
          Please Check The QR Code Again !
        </p>
      );
    }

    localStorage.setItem("table_id", tableDec);
    localStorage.setItem("branch_id", branchDec);
  }

  return (
    <>
      {/* <PreLoader /> */}
      <div className={`${theme ? "bg-[#171717]" : " bg-white"} App`}>
        <Routers />
      </div>
    </>
  );
}

export default App;

const encrypt = (encryptedWord) => {
  const encryptedNumber = AES.encrypt(
    encryptedWord.toString(),
    process.env.REACT_APP_NOT_SECRET_KEY
  ).toString();

  return encryptedNumber;
};

console.log(encrypt(2), encrypt(1));
